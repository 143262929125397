html, body, div, a, p, li, ol, span, textarea {
  font-family: ""; }

a {
  color: #BCE1F7;
  text-decoration: none; }
  a:hover {
    color: #289CFF;
    text-decoration: none; }
  a:focus {
    color: #289CFF;
    text-decoration: none; }
  a p {
    color: #666666; }
    a p:hover {
      color: #289CFF;
      text-decoration: none; }
    a p:focus {
      color: #289CFF;
      text-decoration: none; }
  a .title_color_active {
    color: #333333; }
    a .title_color_active:hover {
      color: #289CFF;
      text-decoration: none; }
    a .title_color_active:focus {
      color: #289CFF;
      text-decoration: none; }

.title_color {
  color: #BCE1F7;
  text-decoration: none; }
  .title_color:hover {
    color: #289CFF;
    text-decoration: none; }
  .title_color:focus {
    color: #289CFF;
    text-decoration: none; }

.container, p {
  padding: 0; }

p, div, span, img, a, h1, h2, h3, h4, h5, h6, ul, li {
  margin: 0;
  padding: 0; }

.jumbotron {
  background-color: transparent;
  padding: 0;
  margin: 0; }
  .jumbotron p {
    padding: 0;
    margin: 0; }

.row {
  margin: 0px;
  padding: 0px; }
  .row div {
    padding: 0px; }

@media screen and (min-width: 678px) {
  body {
    padding-top: 0px; } }

html {
  font-size: 16px;
  background-color: #F8F8F8; }

@media screen and (min-width: 320px) {
  html {
    font-size: 16px !important; } }

@media screen and (min-width: 360px) {
  html {
    font-size: 18px !important; } }

@media screen and (min-width: 375px) {
  html {
    font-size: 18.75px !important; } }

@media screen and (min-width: 384px) {
  html {
    font-size: 19.2px !important; } }

@media only screen and (min-width: 400px) {
  html {
    font-size: 20px !important; } }

@media only screen and (min-width: 411px) {
  html {
    font-size: 20.55px !important; } }

@media only screen and (min-width: 412px) {
  html {
    font-size: 20.6px !important; } }

@media screen and (min-width: 414px) {
  html {
    font-size: 20.7px !important; } }

@media screen and (min-width: 423px) {
  html {
    font-size: 21.15px !important; } }

@media screen and (min-width: 424px) {
  html {
    font-size: 21.2px !important; } }

@media screen and (min-width: 480px) {
  html {
    font-size: 24px !important; } }

@media screen and (min-width: 600px) {
  html {
    font-size: 30px !important; } }

@media screen and (min-width: 763px) {
  html {
    font-size: 38.15px !important; } }

@media screen and (min-width: 768px) {
  .container {
    width: 768px !important; } }

@media screen and (min-width: 1024px) {
  .container {
    width: 1024px !important; } }

@media screen and (min-width: 1170px) {
  .container {
    width: 1170px !important; } }

div.title_name {
  font-family: "";
  font-size: 30px;
  color: #333333;
  height: 190px;
  width: 252px;
  margin: 0 auto;
  text-align: center;
  padding-top: 63px;
  background: url("../../image/shape_triangle.png") center no-repeat; }
  div.title_name p {
    text-align: center; }
    div.title_name p:nth-child(1) {
      font-family: "";
      color: #333333;
      font-size: 30px;
      letter-spacing: 0;
      line-height: 42px;
      margin-bottom: 17px; }
    div.title_name p:nth-child(2) {
      font-family: "";
      color: #666666;
      font-size: 20px;
      line-height: 15px;
      letter-spacing: 0; }

.core_display {
  display: block; }

.core_display_none {
  display: none; }

.breadcrumb > li:nth-child(1) {
  padding-left: 0px; }

.breadcrumb > li + li:before {
  content: "" !important;
  background: url(../../image/lever.png) no-repeat !important;
  background-size: 20px 20px !important;
  padding: 0 10px !important;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: 0; }

.breadcrumb > li {
  position: relative;
  padding-left: 20px;
  font-family: "";
  color: #666666; }
  .breadcrumb > li > a {
    color: #666666;
    font-family: ""; }
    .breadcrumb > li > a:hover {
      color: #289CFF;
      text-decoration: none; }
    .breadcrumb > li > a:focus {
      color: #289CFF;
      text-decoration: none; }
  .breadcrumb > li p {
    display: inline-block; }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    margin-left: 20px; } }

.top {
  letter-spacing: 0;
  text-align: center !important;
  background: #289CFF;
  position: fixed;
  right: 0;
  cursor: pointer;
  border-radius: 50%;
  z-index: 10000;
  color: #ffffff; }
  .top:hover {
    background: #337ab7; }
  .top p a {
    font-family: "";
    color: #FFFFFF;
    width: 48px;
    height: 48px;
    display: block; }
  @media screen and (max-width: 760px) {
    .top {
      width: 2.34rem;
      height: 2.34rem;
      line-height: 2.34rem;
      top: 90%; }
      .top p a {
        font-size: 0.64rem;
        width: 2.34rem;
        height: 2.34rem; } }
  @media screen and (min-width: 760px) {
    .top {
      width: 48px;
      height: 48px;
      line-height: 48px;
      top: 90%; }
      .top p a {
        font-size: 14px; } }

.caret {
  display: none !important; }

@media only screen and (max-width: 768px) {
  .login {
    padding-left: 0;
    background: url("../../image/login-mobile.png") left center no-repeat;
    background-size: cover; }
    .login .login_info {
      width: 15.2rem;
      height: 24rem;
      top: 50%;
      left: 50%;
      margin-top: -12rem;
      margin-left: -7.6rem; }
      .login .login_info .login_title {
        width: 7.89rem;
        height: 1.6rem;
        padding-top: 0.13rem;
        padding-left: 2.12rem;
        font-size: 0.96rem;
        line-height: 1.33rem;
        background: url("../../image/cy-logo-mobile.png") left center no-repeat;
        background-size: 1.6rem 1.6rem;
        margin: 2.4rem auto 0; }
      .login .login_info .login_text {
        margin-top: 10.05rem;
        width: inherit;
        height: 4.48rem; }
        .login .login_info .login_text input {
          width: 12.8rem !important;
          height: 1.92rem;
          font-family: "";
          font-size: 0.64rem;
          color: #FFFFFF;
          letter-spacing: 0;
          border: 1px solid #FFFFFF;
          border-radius: 0.16rem;
          background: transparent;
          padding-left: 1rem;
          padding-top: 0.74rem;
          padding-bottom: 0.74rem;
          margin-left: 1.22rem; }
        .login .login_info .login_text input:nth-last-child(2) {
          background: url("../../image/userid-mobile.png") left center no-repeat;
          background-size: 0.96rem;
          margin-bottom: 0.64rem; }
        .login .login_info .login_text input:nth-last-child(1) {
          background: url("../../image/password-mobile.png") left center no-repeat;
          background-size: 0.96rem; }
      .login .login_info .login_btn {
        width: 10.08rem !important;
        height: 1.92rem; }
        .login .login_info .login_btn button {
          width: 10.08rem !important;
          height: 1.92rem;
          font-size: 0.64rem;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          line-height: 0.42rem;
          padding: 0.74rem auto;
          margin-left: 2.64rem;
          margin-top: 1.28rem; } }

/*# sourceMappingURL=../../../../../../../../static/website/css/website/login-mobile.css.map */